





















import { Component, Prop, Vue } from 'vue-property-decorator'
import { format } from 'date-fns'

@Component
export default class EPaperWidget extends Vue {
  @Prop({ type: String, default: 'https://epaper.vn.at' })
  readonly site!: string

  @Prop({ type: String, default: 'https://aboshop.vn.at' })
  readonly aboshop!: string

  @Prop({ type: Boolean, default: true })
  readonly showAboshopButton!: boolean

  @Prop({ type: String, default: undefined }) readonly title!: string | null

  get coverSource() {
    const cacheKey = format(new Date(), 'yyyyMMddHH')
    return `${this.site}/current-cover.jpg?v=${cacheKey}`
  }

  get epaperUrl() {
    return this.$store.getters['app/isApp']
      ? 'MPS.showShelf://?categoryKey=aktuell'
      : this.site
  }

  track() {
    this.$store.dispatch('tracking/trackGeneric', {
      category: 'epaper',
      action: 'click',
    })
  }
}
